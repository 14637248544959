<template>
  <h6 class="text-subtitle2 q-ml-lg">
    {{ $t("login.recover.titleRecovery") }}
  </h6>
  <p class="text-subtitle3 q-ml-lg q-mr-lg text-justify">
    {{ $t("login.recover.change_password.orientation_first") }}
  </p>
  <p class="text-subtitle3 q-ml-lg q-mr-lg text-justify q-mb-lg">
    {{ $t("login.recover.change_password.orientation_second") }}
  </p>
  <Form
    class="q-gutter-md"
    :initial-values="initialValues"
    :validation-schema="schema"
    @submit="onSubmit"
  >
    <q-card-section>
      <Field name="password" v-slot="{ errorMessage, value, field }">
        <q-input
          type="password"
          filled
          autocomplete="on"
          :label="labels.password"
          :model-value="value"
          v-bind="field"
          :error-message="errorMessage"
          :error="!!errorMessage"
        />
      </Field>

      <Field
        name="passwordConfirmation"
        v-slot="{ errorMessage, value, field }"
      >
        <q-input
          type="password"
          filled
          autocomplete="on"
          :label="labels.passwordConfirmation"
          :model-value="value"
          v-bind="field"
          :error-message="errorMessage"
          :error="!!errorMessage"
        />
      </Field>

      <q-btn
        class="q-mt-md q-mb-lg bg-custom-color"
        type="submit"
        :label="$t('login.recover.submit')"
        :loading="in_request"
        :disable="in_request"
      />
    </q-card-section>
  </Form>
</template>

<script>
import { useActions, useGetters } from "vuex-composition-helpers";
import { rules, labels } from "@/shared/form-presets";
import { useRouter, useRoute } from "vue-router";
import { Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { Notify } from "quasar";
import * as yup from "yup";
import { ref } from "vue";

export default {
  name: "ChangePassword",

  components: {
    Field,
    Form,
  },

  setup() {
    const { t } = useI18n(),
      in_request = ref(false),
      router = useRouter(),
      route = useRoute(),
      initialValues = {};

    /**
     * definitions for form schema
     */
    const schema = (() => {
      const { password, passwordConfirmation } = rules;
      return yup.object({ password, passwordConfirmation });
    })();

    const { changePassword } = useActions({
        changePassword: "auth/changePassword",
      }),
      { partner } = useGetters({
        partner: "partner/getPartner",
      });

    const onSubmit = (values, actions) => {
      in_request.value = true;

      changePassword({
        password: values.password,
        code: route.params.code,
        email: route.params.email,
        slug: route.params.slug,
      })
        .then(() => {
          Notify.create({
            message: t("notify.success"),
            type: "positive",
            color: "light-blue-8",
            position: "top-right",
          });

          router.push({
            name: "login",
            params: {
              slug: route.params.slug,
            },
          });
        })
        .catch(({ errors, message }) => {
          actions.setFieldValue("password", "");
          actions.setFieldValue("passwordConfirmation", "");

          const notify = (error) => {
            Notify.create({
              message: error,
              position: "top-right",
              color: "red",
              icon: "fas fa-exclamation",
            });
          };

          if (errors) Object.values(errors).forEach(notify);
          if (message) notify(message);
        })
        .finally(() => {
          in_request.value = false;
        });
    };

    return {
      partner,
      in_request,
      labels,
      schema,
      initialValues,
      onSubmit,
    };
  },
};
</script>
